<template>
  <div v-if="type === 'textfield'">
    <v-text-field
      :ref="id"
      v-model="data"
      :label="label"
      :disabled="disabled"
      clearable
      :type="inputType"
      outlined
      dense
      :error-messages="errorMessage"
      @input="updateValue"
      @blur="onInputBlur"
      @keydown.enter="onEnter(id)"
      :append-icon="appendIcon"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "InputField",

  props: {
    type: { type: String, required: true },
    label: { type: String, required: true },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    id: { default: "" },
    number: { type: Boolean, default: false },
    appendIcon: { type: String, default: "mdi-barcode-scan" },
    assumedData: { type: String },
    onError: { type: String, default: "" },
    default: {},
    max: { type: Number },
    onEnter: { type: Function },
    onBlur: { type: Function, default: () => {} },
  },

  data: () => ({
    data: null,
    errorMessage: null,
  }),

  methods: {
    checkErrorStatus: function () {
      if (this.required && !this.data) {
        this.errorMessage = this.$t("this_field_is_required");
      } else if (this.onError) {
        this.errorMessage = this.onError;
      } else this.errorMessage = null;
    },

    updateValue: function () {
      this.checkErrorStatus();
      this.$emit("update-value", this.data, this.id);
    },

    clearField: function () {
      if (this.id === "qty") {
        this.data = 0;
      } else this.data = null;
    },

    setValue(value) {
      this.data = value;
    },

    onInputBlur() {
      this.checkErrorStatus();
      this.onBlur();
    },
  },

  computed: {
    inputType: function () {
      return this.type === "textfield" && this.number ? "number" : "text";
    },
  },

  watch: {
    onError(val) {
      this.errorMessage = val;
    },
  },
  mounted() {
    this.data = this.default;
  },
};
</script>
