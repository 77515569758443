<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Tote Scan</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div>
          <div>
            <v-row>
              <v-col cols="12">
                <div>
                  <v-text-field
                    v-model.trim="formData.tote_id"
                    label="Tote"
                    clearable
                    outlined
                    autofocus
                    dense
                    :disabled="disabled.tote_id"
                    append-icon="mdi-barcode-scan"
                    @click:append="onEnterTote"
                    @keyup.enter="onEnterTote"
                    :error-messages="toteError"
                  ></v-text-field>
                </div>
                <div>
                  <v-text-field
                    v-model.trim="formData.location"
                    ref="location"
                    label="Location"
                    clearable
                    outlined
                    dense
                    :disabled="disabled.location"
                    append-icon="mdi-barcode-scan"
                    @click:append="onEnterLocation"
                    @keyup.enter="onEnterLocation"
                    :error-messages="locationError"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            ref="submit"
            type="submit"
            class="btn btn-info px-5 mx-5 py-3 ls1"
            @click.prevent="submit"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  SET_PUTAWAY_MODAL_TABLE_DATA,
  ADJUST_PUTAWAY_MODAL_TABLE_DATA,
} from "@/core/services/store/putawayTasks.module";

export default {
  name: "ScanToteModal",
  data: () => ({
    dialog: false,
    valid: true,
    formData: { tote_id: null, location: null },
    disabled: { tote_id: false, location: true },
    locationValidated: false,
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.clearFields();
      }
      this.dialog = !this.dialog;
    },
    clearFields() {
      this.formData = { tote_id: null, location: null };
      this.disabled = { tote_id: false, location: true };
      this.valid = true;
      this.locationValidated = false;
    },
    onEnterTote() {
      if (this.formData.tote_id) {
        this.$store.commit(SET_PAGE_LOADING, true);

        const payload = {
          warehouse_id: this.selectedWarehouse,
          validate_field: "tote_id",
          tote_id: this.formData.tote_id,
        };
        ApiService.post(
          `/warehouse_management/putaway_tasks/validate_manual_putaway`,
          payload
        )
          .then(({ data }) => {
            if (data.success) {
              this.disabled.location = false;
              setTimeout(() => {
                this.$refs.location.focus();
              }, 100);
            }
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    onEnterLocation() {
      //check locked location
      this.$store.commit(SET_PAGE_LOADING, true);
      const payload = {
        warehouse_id: this.selectedWarehouse,
        location: this.formData.location,
      };
      ApiService.post(
        `/warehouse_management/warehouses/check_locked_location`,
        payload
      )
        .then(() => {
          this.locationValidated = true;
          setTimeout(() => {
            this.$refs.submit.focus();
          }, 100);
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    async submit() {
      if (
        !this.formData.tote_id ||
        !this.formData.location ||
        !this.locationValidated
      ) {
        this.valid = false;
        return;
      } else {
        this.valid = true;

        this.$store.commit(SET_PAGE_LOADING, true);

        const payload = {
          warehouse_id: this.selectedWarehouse,
          tote_id: this.formData.tote_id,
          location: this.formData.location,
        };
        ApiService.post(
          `/warehouse_management/putaway_tasks/putaway_by_tote_scan`,
          payload
        )
          .then(({ data }) => {
            Swal.fire({
              title: "Success",
              text: "Putaway updated successfully",
              icon: "success",
              showConfirmButton: true,
              showCancelButton: false,
              focusConfirm: true,
              timer: 2500,
            });

            const task = data.putaway_task;
            const duplicateIndex = this.putawayTableData.findIndex(
              (item) =>
                item.tote_id === task.tote_id &&
                item.sku === task.sku &&
                item.location === task.location
            );
            // const duplicateIndex = this.putawayTableData.findIndex(
            //   (item) =>
            //     item.tote_id === task.tote_id && item.location === task.location
            // );
            if (duplicateIndex === -1) {
              this.$store.commit(SET_PUTAWAY_MODAL_TABLE_DATA, task);
            } else {
              this.$store.commit(ADJUST_PUTAWAY_MODAL_TABLE_DATA, {
                index: duplicateIndex,
                payload: task,
              });
            }

            this.clearFields();
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
  },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    toteError: function () {
      if (!this.valid && !this.formData.tote_id) {
        return "This field is required";
      }
      return null;
    },
    locationError: function () {
      if (!this.valid && !this.formData.location) {
        return "This field is required";
      }
      return null;
    },
    putawayTableData() {
      return this.$store.getters.getPUTAWAYModalTableData;
    },
  },
  mounted() {},
};
</script>
