<template>
  <div>
    <v-dialog
      v-model="showModal"
      persistent
      max-width="800"
      transition="dialog-bottom-transition"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">Putaway</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column h-550px scroll-y mx-5 py-7">
          <!--begin::Body-->
          <div>
            <div>
              <div class="bg-secondary rounded p-6 mb-3">
                <v-row>
                  <v-col cols="auto">
                    <v-row class="bg-secondary rounded mt-0">
                      <v-col cols="auto" class="font-weight-black py-0 pr-0">
                        SKU:
                      </v-col>
                      <v-col v-if="sku" class="py-0">{{ sku }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="auto">
                    <v-row class="bg-secondary rounded">
                      <v-col cols="auto" class="font-weight-black pr-0">
                        Description:
                      </v-col>
                      <v-col>{{ skuDescription }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <div class="bg-secondary rounded p-6 mb-8">
                <v-row>
                  <v-col cols="auto">
                    <v-row v-if="putawayTaskItemData">
                      <v-col cols="auto" class="font-weight-black pr-0">
                        Tote:
                      </v-col>
                      <v-col>
                        <div v-if="tote">
                          {{ tote }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="auto">
                    <v-row v-if="putawayTaskItemData">
                      <v-col cols="auto" class="font-weight-black pr-0">
                        Suggested Location:
                      </v-col>
                      <v-col>
                        <div v-if="suggestedLocation">
                          {{ suggestedLocation.name }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="auto">
                    <v-row class="bg-secondary rounded">
                      <v-col cols="auto" class="font-weight-black pr-0">
                        Expected Quantity:
                      </v-col>
                      <v-col v-if="quantity !== null">{{ quantity }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <hr />
              <v-row class="mt-6">
                <v-col cols="12" class="d-flex justify-content-end">
                  <b-button
                    :disabled="!quantity"
                    @click="() => toggleScannerModal('solo')"
                    variant="-export-filter"
                    :class="[
                      {
                        'black--text': !quantity,
                      },
                    ]"
                    >Scan
                  </b-button>
                  <b-button
                    v-if="permissionChecker('update_putaway_qty')"
                    :disabled="!quantity"
                    @click="() => toggleScannerModal('bulk')"
                    variant="-export-filter"
                    :class="[
                      {
                        'black--text': !quantity,
                      },
                      { 'ml-3': true },
                    ]"
                    >Bulk scan</b-button
                  >
                  <v-tooltip
                    v-else
                    top
                    open-delay="500"
                    colse-delay="300"
                    max-width="250"
                    content-class="text-center"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <b-button
                        class="ml-3"
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        >Bulk scan</b-button
                      >
                    </template>
                    <span
                      >You don't have necessary permission for bulk
                      scanning.</span
                    >
                  </v-tooltip>

                  <b-button
                    :disabled="!quantity"
                    variant="-export-filter"
                    :class="[
                      {
                        'black--text': !quantity,
                      },
                    ]"
                    class="ml-3"
                    @click="claimRestMissing"
                    >Rest missing</b-button
                  >
                </v-col>
                <v-col cols="12">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="putawayTableData"
                    item-key="id"
                    class="my-6 pl-4 pr-8"
                    hide-default-footer
                    mobile-breakpoint="300"
                    :item-class="secondText"
                  >
                  </v-data-table>
                </v-col>
              </v-row>
            </div>
          </div>
          <!--end::Body-->
          <!--begin::Actions-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
            <button
              :disabled="!previousItem"
              type="reset"
              class="btn btn--export-filter px-5 py-3"
              @click="previous"
            >
              Previous
            </button>
            <button
              type="reset"
              class="btn btn--export-filter px-5 py-3 mx-8"
              @click="toggleModal"
            >
              Close
            </button>
            <button
              :disabled="!nextItem"
              type="reset"
              class="btn btn--export-filter px-5 py-3"
              @click="next"
            >
              Next
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
    <ScannerModal
      ref="scannerModal"
      v-on:navigate-to-next="next"
      v-on:navigate-to-previous="previous"
      v-on:update-item-index="updateIndex"
    />
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import ScannerModal from "@/own/components/warehouseManagement/putawayTasks/ScannerModal";
import {
  SET_PUTAWAY_MODAL_EMPTY,
  UPDATE_TABLE_DATA,
  SET_PUTAWAY_MODAL_TABLE_DATA,
  ADJUST_PUTAWAY_TASK_DATA,
  SET_SCANNING_ACTION_TYPE,
  GET_PUTAWAY_TASK_DATA,
  SET_PUTAWAY_ITEM_FOR_ACTION,
} from "@/core/services/store/putawayTasks.module";
import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
export default {
  name: "PutawayModal",
  components: { ScannerModal },
  data: () => ({
    showModal: false,
    showScannerModal: false,
    headers: [
      {
        text: "Tote",
        align: "start",
        sortable: false,
        value: "tote_id",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "SKU",
        align: "start",
        sortable: false,
        value: "sku",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Quantity",
        align: "start",
        sortable: false,
        value: "qty",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      {
        text: "Location",
        align: "start",
        sortable: false,
        value: "location",
        class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
        cellClass: "second-text",
      },
      // {
      //   text: "Actions",
      //   align: "start",
      //   sortable: false,
      //   value: "action",
      //   class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
      //   cellClass: "second-text",
      // },
    ],
    nextIndex: null,
  }),
  methods: {
    toggleModal() {
      if (!this.showModal) this.$store.commit(SET_PUTAWAY_MODAL_EMPTY);
      this.showModal = !this.showModal;
    },
    toggleScannerModal(type) {
      this.$store.commit(SET_SCANNING_ACTION_TYPE, type);
      setTimeout(() => {
        this.$refs.scannerModal.toggleModal();
      });
    },
    claimRestMissing() {
      Swal.fire({
        title: "Rest missing",
        text: "Do you want to claim rest missing?",
        icon: "question",
        showConfirmButton: true,
        showCancelButton: true,
        focusConfirm: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.confirmRestMissing();
        } else return;
      });
    },
    confirmRestMissing() {
      this.$store.commit(SET_PAGE_LOADING, true);
      const payload = {
        warehouse_id: this.selectedWarehouse,
        id: this.putawayTaskItemData.putaway_task.id,
        tote_id: this.putawayTaskItemData.putaway_task.tote_id,
        sku: this.putawayTaskItemData.putaway_task.sku.sku,
        qty: this.putawayTaskItemData.putaway_task.expected_qty,
        location: this.putawayTaskItemData.missing_location.name,
      };
      ApiService.post(`/warehouse_management/putaway_tasks/update`, payload)
        .then(() => {
          this.$store.commit(SET_PUTAWAY_MODAL_TABLE_DATA, payload);
        })
        .then(() => {
          Swal.fire({
            title: "Sucess",
            text: "Rest missing claimed",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
        })
        .then(() => {
          this.$store.commit(ADJUST_PUTAWAY_TASK_DATA, payload.qty);
          this.$store.dispatch(UPDATE_TABLE_DATA, {
            warehouse_id: this.selectedWarehouse,
          });
          this.next();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    secondText() {
      return "second-text";
    },
    previous() {
      if (this.previousItem) {
        //move putawayItemForAction for action
        const index = this.items.findIndex(
          (index) => index.id === this.previousItem.id
        );
        this.$store.commit(SET_PUTAWAY_ITEM_FOR_ACTION, index);
        // fetch the next available data
        this.$store.commit(SET_PAGE_LOADING, true);
        this.$store.dispatch(GET_PUTAWAY_TASK_DATA, {
          id: this.previousItem.id,
        });
        this.nextIndex = null;
      }
    },
    next() {
      if (this.nextItem) {
        //move putawayItemForAction for action
        const index = this.items.findIndex(
          (index) => index.id === this.nextItem.id
        );
        this.$store.commit(SET_PUTAWAY_ITEM_FOR_ACTION, index);
        // fetch the next available data
        this.$store.commit(SET_PAGE_LOADING, true);
        this.$store.dispatch(GET_PUTAWAY_TASK_DATA, { id: this.nextItem.id });
        this.nextIndex = null;
      }
    },
    updateIndex(index) {
      this.nextIndex = index;
    },
  },
  computed: {
    putawayTableData() {
      return this.$store.getters.getPUTAWAYModalTableData;
    },
    putawayItemForAction() {
      return this.$store.getters.getPUTAWAYITEMForAction;
    },
    putawayTaskItemData() {
      return this.$store.getters.getPUTAWAYTASKItemData;
    },
    selectedWarehouse() {
      return this.$store.getters.getSelectedWarehouse;
    },
    suggestedLocation() {
      return this.putawayTaskItemData?.putaway_task?.suggested_location;
    },
    sku() {
      return this.putawayTaskItemData?.putaway_task?.sku.sku;
    },
    tote() {
      return this.putawayTaskItemData?.putaway_task?.tote_id;
    },
    quantity() {
      return this.putawayTaskItemData?.putaway_task?.expected_qty;
    },
    table_permissions: function () {
      if (this.$store.getters.getPUTAWAYTASKSTablePermissions) {
        return this.$store.getters.getPUTAWAYTASKSTablePermissions.map(
          function (perm) {
            return perm.name;
          }
        );
      } else {
        return false;
      }
    },
    skuDescription: function () {
      return this.putawayTaskItemData?.putaway_task?.sku?.description;
    },
    putawaytaskTableData() {
      return this.$store.getters.getPUTAWAYTASKSTableData.data;
    },
    items: function () {
      let items = [];
      if (this.$store.getters.getPUTAWAYTASKSTableData) {
        items = this.$store.getters.getPUTAWAYTASKSTableData?.data;
      }
      return items;
    },
    nextItem: function () {
      // find current item index
      let index = null;
      if (this.nextIndex) {
        index = this.nextIndex;
      } else {
        index = this.putawaytaskTableData.findIndex(
          (item) => item.id === this.putawayTaskItemData?.putaway_task?.id
        );
      }
      //find next pending or partially completed item
      return this.putawaytaskTableData
        .slice(index + 1)
        .find(
          (item) =>
            item.status === "Pending" || item.status === "Partially Completed"
        );
    },
    previousItem() {
      // find current item index
      const index = this.putawaytaskTableData.findIndex(
        (item) => item.id === this.putawayTaskItemData?.putaway_task?.id
      );
      //find previous pending or partially completed item
      return this.putawaytaskTableData
        .slice(0, index)
        .findLast(
          (item) =>
            item.status === "Pending" || item.status === "Partially Completed"
        );
    },
  },
};
</script>
