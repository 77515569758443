<template>
  <div class="custom-content-height">
    <v-data-table
      fixed-header
      light
      dense
      :height="getvh(56)"
      :items="items"
      item-key="id"
      class="mx-2 mt-0 font-weight-bold"
      :server-items-length="items.length"
      :headers="headers"
      :items-per-page="itemPerPage"
      hide-default-footer
      mobile-breakpoint="300"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE!</template>
      <template v-slot:no-results> NO RESULTS HERE!</template>
      <template v-slot:top>
        <!-- header start -->
        <div class="d-flex justify-content-between mt-5">
          <datatable-detail
            :table_properties="table_properties"
          ></datatable-detail>
          <div class="mr-md-5">
            <div class="d-none d-md-block">
              <button
                class="btn poppins mr-2 iq-btn"
                @click="$refs.easy_putaway.toggleModal()"
              >
                Easy putaway
              </button>
              <button
                class="btn poppins mr-2 iq-btn"
                @click="$refs.manual_putaway.toggleModal()"
              >
                Manual putaway
              </button>
              <button
                class="btn poppins mr-2 btn--export-filter"
                @click="$refs.exportSidebar.$data.exportSidebar = true"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-export-variant</v-icon>
                </span>
                Export
              </button>
              <button
                class="btn poppins mr-2 btn--export-filter"
                @click="$refs.filterSidebar.$data.filterSidebar = true"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                Filter
              </button>
            </div>
            <div class="d-block d-md-none">
              <b-button
                depressed
                small
                variant="outline-primary"
                class="px-5 py-3 mb-1 btn--tools"
                @click="toolBarVisionState = !toolBarVisionState"
              >
                TOOLS
              </b-button>
            </div>
          </div>
        </div>
        <div v-if="isToolbarVisiable" class="d-flex justify-content-center">
          <div>
            <button
              class="btn poppins mr-2 iq-btn mt-5"
              @click="() => $refs.easy_putaway.toggleModal()"
            >
              Easy putaway
            </button>
            <button
              class="btn poppins mr-2 iq-btn mt-5"
              @click="() => $refs.manual_putaway.toggleModal()"
            >
              Manual putaway
            </button>
            <button
              class="btn poppins mr-2 btn--export-filter mt-5"
              @click="() => ($refs.exportSidebar.$data.exportSidebar = true)"
            >
              <span class="svg-icon">
                <v-icon size="18">mdi-export-variant</v-icon>
              </span>
              Export
            </button>
            <button
              class="btn poppins mr-2 btn--export-filter mt-5"
              @click="() => ($refs.filterSidebar.$data.filterSidebar = true)"
            >
              <span class="svg-icon">
                <v-icon size="18">mdi-filter</v-icon>
              </span>
              Filter
            </button>
          </div>
        </div>
        <div
          class="min-w-250px mb-6 max-w-500px pl-4 mt-8 font-weight-medium w-100 text-capitalize"
        >
          <v-text-field
            v-model="toteSearch"
            label="Scan Tote"
            type="text"
            autofocus
            outlined
            multiple
            clearable
            class="poppins"
            append-icon="mdi-barcode-scan"
            @click:append="filterByTote"
            @keyup.enter="filterByTote"
            @click:clear="filterByTote('clear')"
          />
        </div>

        <!-- Filter Sidebar start -->
        <FilterSidebar
          v-if="filters.length > 0"
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->
        <!-- Export Sidebar start -->
        <ExportSidebar
          v-if="permissionChecker('export') && exportHeaders"
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->
      </template>
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>

      <template #item.action="{ item }">
        <div class="d-flex align-items justify-center">
          <button
            :disabled="!item.qty"
            class="btn poppins mr-2"
            :class="[{ 'btn-secondary': !item.qty }, { 'iq-btn': item.qty }]"
            @click="() => showPutawayModal(item)"
          >
            Putaway
          </button>
        </div>
      </template>
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="getItemPerPage"
      :getpageNumber="getPageNumber"
      :setpageNumber="setPageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <PutawayModal ref="putawayModal" />
    <component
      v-bind:is="easyPutaway"
      ref="easy_putaway"
      :refresher="updateTableContent"
    ></component>
    <component
      v-bind:is="manualPutaway"
      ref="manual_putaway"
      :refresher="updateTableContent"
    ></component>
  </div>
</template>

<script>
import ComponentSelector from "@/own/components/datatable/ComponentSelector";
import PutawayModal from "@/own/components/warehouseManagement/putawayTasks/PutawayModal";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
// import ApiService from "@/core/services/api.service";
import Pagination from "@/own/components/pagination/Pagination";
import {
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  UPDATE_TABLE_DATA,
  GET_PUTAWAY_TASK_DATA,
  SET_PUTAWAY_ITEM_FOR_ACTION,
  SET_TABLE_FILTER,
  EXPORT_TABLE_DATA,
} from "@/core/services/store/putawayTasks.module";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar.vue";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";
import EasyPutaway from "@/own/components/warehouseManagement/putawayTasks/EasyPutawayModal.vue";
import ManualPutaway from "@/own/components/warehouseManagement/putawayTasks/ManualPutaway.vue";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar.vue";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  components: {
    Pagination,
    ComponentSelector,
    PutawayModal,
    DatatableDetail,
    FilterSidebar,
    EasyPutaway,
    ExportSidebar,
  },
  data: () => ({
    toteSearch: null,
    easyPutaway: EasyPutaway,
    manualPutaway: ManualPutaway,
    toolBarVisionState: false,
  }),
  methods: {
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    async updateTableContent() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters.getPUTAWAYTASKSTableState,
        warehouse_id: this.selectedWarehouse,
      };
      await this.$store.dispatch(UPDATE_TABLE_DATA, state).then(() => {
        this.$store.commit(SET_PAGE_LOADING, false);
      });
      this.$forceUpdate();
    },
    showPutawayModal(item) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.dispatch(GET_PUTAWAY_TASK_DATA, { id: item.id });
      const index = this.items.findIndex((index) => index.id === item.id);
      this.$store.commit(SET_PUTAWAY_ITEM_FOR_ACTION, index);
      this.$refs.putawayModal.toggleModal();
    },
    setTableFiltersValues(val) {
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store.commit(SET_TABLE_FILTER, []);
      let state = {
        ...this.$store.getters.getPUTAWAYTASKSTableState,
        warehouse_id: this.selectedWarehouse,
      };
      this.$store.dispatch(UPDATE_TABLE_DATA, state).then(() => {
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters.getPUTAWAYTASKSTableState,
        warehouse_id: this.selectedWarehouse,
      };
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);

          // this.$store.commit(SET_PAGE_LOADING, false);
          this.$nextTick(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    handleFilterSidebarClick() {
      this.$refs.putawayTaskFilterSidebar.$data.filterSidebar = true;
    },
    filterByTote(action) {
      if (this.toteSearch) {
        if (action === "clear") {
          this.$store.dispatch(UPDATE_TABLE_DATA, {
            warehouse_id: this.selectedWarehouse,
          });
          this.toteSearch = null;
          return;
        }
        this.$store.commit(SET_PAGE_LOADING, true);
        const payload = {
          page: 1,
          per_page: 50,
          sortBy: "id",
          sortOrder: "desc",
          filters: [
            {
              name: "tote_multiple",
              value: [this.toteSearch],
            },
          ],
          warehouse_id: this.selectedWarehouse,
        };
        this.$store.dispatch(UPDATE_TABLE_DATA, payload);
      }
    },
    downloadItem(columns) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.export_modal = false;
      this.$store
        .dispatch(EXPORT_TABLE_DATA, {
          columns: columns,
          filters: this.$store.getters.getPUTAWAYTASKSTableState.filters,
          warehouse_id: this.selectedWarehouse,
        })
        .then(() => {
          if (this.$store.getters.getPUTAWAYTASKSExportUrl) {
            const link = document.createElement("a");
            link.href = this.$store.getters.getPUTAWAYTASKSExportUrl;
            link.download = "Export.csv";
            link.click();
          }
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
          this.$refs.exportSidebar.$refs.export.toggleModal();
        });
    },
  },
  computed: {
    //pagination
    getItemPerPage: function () {
      return this.$store.getters.getPUTAWAYTASKSTableState.per_page;
    },
    getPageNumber: function () {
      return this.$store.getters.getPUTAWAYTASKSTableState.page;
    },
    table_permissions: function () {
      if (this.$store.getters.getPUTAWAYTASKSPermissions) {
        return this.$store.getters.getPUTAWAYTASKSPermissions.map(function (
          perm
        ) {
          return perm.name;
        });
      } else {
        return false;
      }
    },
    totalItems: function () {
      return this.$store.getters.getPUTAWAYTASKSTableData?.total;
    },
    pageCount: function () {
      return this.$store.getters.getPUTAWAYTASKSTableData?.last_page;
    },
    items: function () {
      let items = [];
      if (this.$store.getters.getPUTAWAYTASKSTableData) {
        items = this.$store.getters.getPUTAWAYTASKSTableData?.data;
      }
      return items;
    },
    itemPerPage: function () {
      return this.$store.getters.getPUTAWAYTASKSTableState.per_page;
    },
    headers: function () {
      let headers = [];

      if (this.$store.getters.getPUTAWAYTASKSTableHeaders.length) {
        headers = this.$store.getters.getPUTAWAYTASKSTableHeaders.filter(
          (col) => col.visible === true
        );
      }
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        if (header.value === "action") {
          header.class =
            "text-center text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
    table_properties: function () {
      return this.$store.getters.getPUTAWAYTASKSTableProperties;
    },
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndDown && this.toolBarVisionState;
    },
    //-------------------filter----------------------
    filters: function () {
      /** @type {Array<{name: string, title: string, type: string, related_columns: string[]}>} */
      let filters = [];
      try {
        filters = this.$store.getters.getPUTAWAYTASKSTableFilters;
      } catch {
        filters = [];
      }
      return filters;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    exportHeaders: function () {
      let headers = null;
      try {
        headers = this.$store.getters.getPUTAWAYTASKSPermissions.filter(
          (col) => col.name === "export"
        )[0].columns;
      } catch {
        headers = null;
      }

      return headers;
    },
  },
};
</script>
